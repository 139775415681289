import { useRouteError, useNavigate } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();
    console.log(error);
    return (
        <div className="errorPage text-center mx-3 my-3">
            <img src="/assets/shesangrywithyou.png" alt="She's angry with you" className="mx-auto w-3/4 mb-3 max-w-lg" />
            <h1 className="text-3xl">Ugh, there was some kind of error.</h1>
            <p>It was definitely your fault, not mine.</p>
            <p className="py-6 font-extrabold text-lg">{error?.statusText || error?.message || error?.data}</p>
            <p>You better get out of here so I can fix this. <span className="font-light text-xs">Idiot.</span></p>
            <button className="btn my-40 bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" onClick={() => {
                navigate(-1);
            }}>Leave quick</button>
        </div>
    )
}