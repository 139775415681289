import SearchBar from '../components/searchBar';
import React from 'react';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web';
import ResultsComponent from './resultsComponent';
import PaginationComponent from './paginationComponent';
import { useSnackbar } from 'notistack'

export default function SearchComponent() {
    const searchClient = instantMeiliSearch(
        'https://search.rakun.app',
        process.env.REACT_APP_MEILISEARCH_KEY,
        {
          primaryKey: 'id',
          placeholderSearch: true,
        }
    )
    const { enqueueSnackbar } = useSnackbar()
    const [type, setType] = React.useState("Anime");
    const [placeholder, setPlaceholder] = React.useState("Search for any Anime");
    const [definedSearch, setDefinedSearch] = React.useState(null);
    const sa_event = window.sa_event;
    React.useEffect(() => {
        switch (type) {
            case "Anime":
                setPlaceholder("Search for any Anime");
                break;
            case "Manga":
                setPlaceholder("Search for any Manga");
                break;
            case "Eroanime":
                setPlaceholder("Search for any Ero-Anime");
                break;
            case "Eromanga":
                setPlaceholder("Search for an Ero-Manga");
                break;
            default:
                setPlaceholder("Search for an Anime");
                break;
        } 
    }, [type, sa_event]);
    return (
        <>
        <img src={`/assets/${type.toLowerCase()}-ryuko.png`} alt={`${type} Logo`} className="mx-auto w-1/2 max-w-xs mb-0" id="ryuko" onClick={() => {
            const favoriteAnimes = [
                "Darling in the Franxx",
                "Oshi No Ko",
                "Attack on Titan",
                "Demon Slayer",
                "Don't Toy With Me, Miss Nagatoro",
            ]
            const favoriteMangas = [
                "Solo Leveling",
                "Berserk",
                "The Devil is a Part-Timer!",
            ]
            //change image-src to different ryuko
            const src = "/assets/embarassed-ryuko.png"
            document.getElementById("ryuko").src = src
            sa_event("ryuko_click")
            if (type === "Anime") {
                var randomAnime = favoriteAnimes[Math.floor(Math.random() * favoriteAnimes.length)]
                setDefinedSearch(randomAnime)
                enqueueSnackbar(`Huh? You're asking me what my favorite is? Well I really like this one...`, { variant: 'info' })
                setPlaceholder(randomAnime)
            } else if (type === "Manga") {
                var randomManga = favoriteMangas[Math.floor(Math.random() * favoriteMangas.length)]
                setDefinedSearch(randomManga)
                enqueueSnackbar(`Huh? You're asking me what my favorite is? Well I really like this one...`, { variant: 'info' })
                setPlaceholder(randomAnime)
            } else {
                enqueueSnackbar(`Huh? No... I don't want to tell you what my favorite is! That's private!`, { variant: 'info' })
            }
            setTimeout(() => {
                document.getElementById("ryuko").src = `/assets/${type.toLowerCase()}-ryuko.png`
            }, 5000);
        }}/>
        <InstantSearch indexName={type} searchClient={searchClient}>
            <Configure hitsPerPage={3} />
            <div className="searchComponent flex justify-center mx-3">
                <div className="flex items-center">
                    <div className="dropdown bw">
                        <label className="gap-2 btn bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white h-10 rounded-r-none" tabIndex="0">Type</label>
                        <div className="menu bottom-right">
                            <p className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" onClick={() => setType("Anime")}>Anime</p>
                            <p className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" 
                            onClick={() => setType("Manga")}
                            >Manga</p>
                            <div className="is-divider" role="separator"></div>
                            <p className="item text-sm bg-gradient-to-l from-violet-500 to-red-500 text-white" 
                            // onClick={() => setType("Eroanime")}
                            onClick={() => enqueueSnackbar('Ero-Anime is currently unavailable for searching.', { variant: 'error', autoHideDuration: 3000 })}
                            >Ero-Anime</p>
                            <p className="item text-sm bg-gradient-to-l from-violet-500 to-red-500 text-white" 
                            // onClick={() => setType("Eromanga")}
                            onClick={() => enqueueSnackbar('Ero-Manga is currently unavailable for searching.', { variant: 'error', autoHideDuration: 3000 })}
                            >Ero-Manga</p>
                        </div>
                    </div>
                    <SearchBar 
                        type={type}
                        placeholder={placeholder}
                        definedSearch={definedSearch}
                        key={type}
                    />
                </div>
            </div>
            <ResultsComponent />
            <div className="flex justify-center">
                <PaginationComponent />
            </div>
        </InstantSearch>
        </>
    )
}