import { usePagination } from 'react-instantsearch-hooks-web';

export default function PaginationComponent() {
    const { pages, currentRefinement, nbHits, isFirstPage, isLastPage, refine } = usePagination();
    var highestPage = Math.round(nbHits / 3)
    return (
        <div className="pagination pb-6">
            {isFirstPage ? (
                <button className="btn compact sm h-8 w-8 border border-current m-1 pointer-events-none" disabled={true}>
                    {"<"}
                </button>
            ) : (
                <button
                    className="btn compact sm h-8 w-8 border border-current m-1"
                    onClick={() => {
                        refine(currentRefinement - 1);
                    }}
                >
                    {"<"}
                </button>
            )}
            {pages.map((page) => {
                let normButtonClass = "btn compact sm h-8 w-8 border border-current m-1"
                if (currentRefinement === page) {
                    normButtonClass = "btn compact sm h-8 w-8 m-1 bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white pointer-events-none"
                }
                //check if last item in array
                return (
                    <button 
                        key={page}
                        className={normButtonClass}
                        onClick={() => {
                            refine(page);
                        }}
                    >
                        {page + 1}
                    </button>
                )
            })}
            {(highestPage-4 !== currentRefinement) && !isLastPage ? (
                <button className="btn compact sm h-8 w-8 m-1 pointer-events-none">
                    ...
                </button>
            ) : (
                null
            )}
            {(highestPage !== currentRefinement) && (highestPage-2 !== currentRefinement) && (currentRefinement < highestPage) && (highestPage-1 !== currentRefinement) ? (
                <> 
                    <button
                        className="btn compact sm h-8 w-8 border border-current m-1"
                        onClick={() => {
                            refine(highestPage);
                        }}
                    >
                        {highestPage}
                    </button>
                </>
            ) : null}
            {isLastPage ? null : (
                <button
                    className="btn compact sm h-8 w-8 border border-current m-1"
                    onClick={() => {
                        refine(currentRefinement + 1);
                    }}
                >
                    {">"}
                </button>
            )}
        </div>
    )
}