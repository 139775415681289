import { useSearchBox } from 'react-instantsearch-hooks-web';
import React from 'react'

export default function SearchBar({ type, placeholder, definedSearch }) {
    const { refine } = useSearchBox();
    const sa_event = window.sa_event;
    React.useEffect(() => {
        if (definedSearch) {
            refine(definedSearch);
            sa_event("search");
        }
    }, [definedSearch, refine, sa_event]);
    return (
        <div className="searchBar flex-grow">
            <input className="input h-10 rounded-l-none w-full max-w-lg border-current border-l-transparent" style={{maxWidth: "512px", width: "76vw"}} type="text" name={type} placeholder={placeholder} onChange={(e)=> {
                refine(e.target.value)
                sa_event("search");
            }}/>
        </div>
    );
  }