import { useLoaderData } from 'react-router-dom';
import React from 'react';
import ThemeChangeButton from '../components/themeChangeButton';
import MenuButton from '../components/menuButton';
import TruncatedText from '../components/truncatedText';
import Sugar from "sugar";
import { DiscussionEmbed } from 'disqus-react';
import { Helmet } from "react-helmet";

export default function MangaPage() {
    const {manga} = useLoaderData();
    const mangaData = manga.detail.data
    return (
        <div className="mangaPage">
            <Helmet>
                <title>{mangaData.title} | Rakun</title>
                <meta name="description" content={mangaData.metadata.synopsis} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${mangaData.title} | Rakun`} />
                <meta property="og:description" content={mangaData.metadata.synopsis} />
                <meta property="og:image" content={mangaData.metadata.poster} />
                <meta property="og:url" content={`https://rakun.app/manga/${mangaData.id}`} />
                <meta property="og:site_name" content="Rakun" />
            </Helmet>
            <div className="header flex justify-between">
                <ThemeChangeButton />
                <MenuButton />
            </div>
            <div className="animePageContent">
                <div className="grid grid-cols-4 gap-4">
                    <div className="sm:col-span-2 col-span-4">
                        {mangaData.metadata.poster ? (
                            <img className="w-80 mx-auto md:mx-3 m-3 rounded-xl" src={mangaData.metadata.poster} alt={mangaData.title} />
                        ): (
                            <img className="w-80 mx-auto md:mx-3 m-3 rounded-xl" src="https://via.placeholder.com/300x400?text=No+Poster" alt={mangaData.title} />
                        )}
                    </div>
                    <div className="sm:col-span-2 col-span-4">
                        <h1 className="text-3xl font-bold sm:w-full w-80 mx-auto sm:mx-3">{mangaData.title}</h1>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.content_type}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.metadata.tags.join(", ")}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.metadata.status}</p>
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Read here:</p>
                        {Array.isArray(mangaData.link) ? (
                            mangaData.link.map((link, index) => {
                                //get key and value from link object
                                const [key, value] = Object.entries(link)[0];
                                return (
                                    <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                        <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${value}`} alt={key} />
                                        <a href={value} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{key}</a>
                                    </div>
                                )
                            })
                        ) : (
                            Object.entries(mangaData.link).map(([key, value], index) => (
                                <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                    <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${value}`} alt={key} />
                                    <a href={value} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{key}</a>
                                </div>
                            ))
                        )}
                    </div>
                    <div className="sm:col-span-2 col-span-4 sm:mb-8">
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Overview</p>
                        <TruncatedText text={mangaData.metadata.synopsis} />
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Release Status</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.metadata.status ? mangaData.metadata.status : "Unknown"}</p>
                        {mangaData.metadata.published ? (
                            <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">From {new Date(mangaData.metadata.published.from).toDateString()} to  
                            <span className="tooltip top bw pl-1" data-tooltip={Sugar.Date.relative(new Date(mangaData.metadata.published.to))}>
                                <span>{new Date(mangaData.metadata.published.to).toDateString()}</span>
                            </span>
                            </p>
                        ) : null}
                        {mangaData.titles.length > 0 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Alternate Titles</p>
                                <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.titles.join(", ")}</p>
                            </>
                        ) : null}
                        
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Ratings</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.metadata.rank ? `Ranked #${mangaData.metadata.rank}` : "Not Ranked"}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">Average Score of {mangaData.metadata.score}/10</p>
                        
                        {mangaData.metadata.authors.length > 1 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Authors</p>
                                <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{mangaData.metadata.authors.join(", ")}</p>
                            </>
                        ) : null}
                        {mangaData.metadata.characters.length > 1 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Characters</p>
                                <TruncatedText text={mangaData.metadata.characters.join("; ")} />
                            </>
                        
                        ) : null}
                        {mangaData.metadata.external_links.length > 1 || mangaData.mal_id || mangaData.ani_id ? (
                            <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">External Links</p>
                        ) : null}
                        {mangaData.mal_id ? (
                            <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1">
                                <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=https://myanimelist.net/`} alt="MyAnimeList" />
                                <a href={`https://myanimelist.net/manga/${mangaData.mal_id}`} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">MyAnimeList</a>
                            </div>
                        ) : null}
                        {mangaData.ani_id ? (
                            <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1">
                                <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=https://anilist.co/`} alt="AniList" />
                                <a href={`https://anilist.co/manga/${mangaData.ani_id}`} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">AniList</a>
                            </div>
                        ) : null}
                        {mangaData.metadata.external_links.map((link, index) => {
                            //get key and value from link object
                            return (
                                <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                    <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${link.url}`} alt={link.name} />
                                    <a href={link.url} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{link.name}</a>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-span-4 mx-3">
                        <DiscussionEmbed
                            shortname='rakunapp'
                            config={
                                {
                                    url: `https://rakun.app/manga/${mangaData.id}`,
                                    identifier: mangaData.id,
                                    title: mangaData.title,
                                    language: 'en',
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}