import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack'

import MainPage from "./pages/main";
import AboutPage from "./pages/about";
import ErrorPage from "./pages/error";
import AnimePage from "./pages/anime";
import MangaPage from "./pages/manga";

var storedTheme =
  localStorage.getItem("theme") ||
  (window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light");
if (storedTheme)
  document.documentElement.setAttribute("data-theme", storedTheme);


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/anime/:id",
    element: <AnimePage />,
    errorElement: <ErrorPage />,
    loader: async ({params}) => {
      const animeData = await fetch(`https://api.rakun.app/get/id/anime/${params.id}`)
      if (animeData.ok) {
        const anime = await animeData.json();
        return { anime };
      } else {
        throw new Response("Anime not found", { status: 404 });
      }
    }
  },
  {
    path: "/manga/:id",
    element: <MangaPage />,
    errorElement: <ErrorPage />,
    loader: async ({params}) => {
      const mangaData = await fetch(`https://api.rakun.app/get/id/manga/${params.id}`)
      if (mangaData.ok) {
        const manga = await mangaData.json();
        return { manga };
      } else {
        throw new Response("Manga not found", { status: 404 });
      }
    }
  },
  {
    path: "*",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);
