import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { Link } from 'react-router-dom';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { IconExternalLink, IconExclamationCircle } from '@tabler/icons-react';
import Sugar from 'sugar';

export default function ResultsComponent() {
    const {results, status} = useInstantSearch();
    const {query} = useSearchBox();
    if (status === 'loading' || status === 'stalled' || status === 'error') {
        return (
            <div className="resultsComponent">
                <div className='grid grid-cols-6 gap-y-2 h-fit m-3 md:my-16 my-2'>
                    <div className='skeleton wave h-28 md:col-start-2 md:col-span-4 col-span-6 rounded-xl' />
                    <div className='skeleton wave h-28 md:col-start-2 md:col-span-4 col-span-6 rounded-xl' />
                    <div className='skeleton wave h-28 md:col-start-2 md:col-span-4 col-span-6 rounded-xl' />
                </div>
            </div>
        )
    } else if (results.hits.length === 0 && query !== "") {
        return (
            <div className="grid grid-cols-6 gap-y-2 h-fit m-3 md:my-16 my-2">
                <div className='prompt danger md:col-start-2 md:col-span-4 col-span-6 h-28 rounded-xl'>
                    <div className='content'>
                        <p>
                            Rakun could not find any results for {query}. Please try again.
                        </p>
                    </div>
                    <div className='title'>
                        Oops! No results found.
                    </div>
                    <div className='icon'>
                        <IconExclamationCircle size={24} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="resultsComponent"> 
                <div className='grid grid-cols-6 gap-y-2 h-fit m-3 md:my-16 my-2'>
                    {results.hits.map((hit) => (
                        <Link key={hit.id} to={`/${hit.type}/${hit.id}`} className='md:col-start-2 md:col-span-4 col-span-6 h-28 rounded-xl'>
                            <div className='col-start-2 col-span-4 h-28 rounded-xl border border-current'>
                                <div className='flex flex-column h-full'>
                                    {
                                        hit.metadata.poster === null ? 
                                        <img src='https://via.placeholder.com/77x110.png?text=No+Poster' className='rounded-l-xl overflow-hidden overflow-ellipsis' alt={hit.title}/> :
                                        <img src={hit.metadata.poster} className='rounded-l-xl overflow-hidden overflow-ellipsis' alt={hit.title}/>
                                    }
                                    <div className='flex flex-col p-2'>
                                        <p className='w-fit font-bold from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent line-clamp-2'>{hit.title}
                                        {
                                            hit.metadata.aired ?
                                            <span className='text-gray-400 text-xs antialiased pl-1 font-light'>{Sugar.Date.format(new Date(hit.metadata.aired.from), '(%h, %G)')}</span> : null
                                        }
                                        {
                                            hit.metadata.published ?
                                            <span className='text-gray-400 text-xs antialiased pl-1 font-light'>{Sugar.Date.format(new Date(hit.metadata.published.from), '(%h, %G)')}</span> : null
                                        }
                                        </p>
                                        <p className='w-fit text-xs md:text-sm text-gray-400 antialiased'>{hit.type === hit.content_type ? null : `${hit.type}, `}{hit.content_type}</p>
                                        <p className='w-fit text-xs md:text-sm text-gray-400 antialiased'>{hit.link.length ? `${hit.link.length} sources` : "1 Source"} </p>
                                        <p className='w-fit text-xs md:text-sm text-gray-400 antialiased line-clamp-1'>{hit.metadata.tags.join(", ")}</p>
                                    </div>
                                    <div className='flex flex-row justify-end p-2 grow'>
                                        <IconExternalLink className="flex justify-end"/>
                                    </div>        
                                </div>
                            </div>  
                        </Link>
                    ))}
                </div>
            </div>
        )
    }
}