import React, { useState, useEffect, useRef } from 'react';
import { IconClockHour8, IconCircleCheck } from '@tabler/icons-react';
import Sugar from 'sugar';

const LatestEpisodes = ({ animeData }) => {
  const [showMore, setShowMore] = useState(false);
  const totalEpisodes = animeData.metadata.episodes.length;
  const displayedEpisodes = showMore
    ? animeData.metadata.episodes
    : animeData.metadata.episodes.slice(Math.max(totalEpisodes - 10, 0));
  const contentRef = useRef(null);

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  useEffect(() => {
    if (showMore) {
      setTimeout(() => {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 100);
    }
  }, [showMore]);

  return (
    <div>
      <div>
        {displayedEpisodes
          .slice()
          .reverse()
          .map((episode) => (
            <div key={episode.number} className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1">
              <p className="text-lg pr-3">{episode.number}</p>
              <p className="text-lg sm:w-5/6 w-80">{episode.title}</p>
              {episode.aired ? (
                <span
                  className="tooltip left bw"
                  data-tooltip={`${new Date(episode.aired).toDateString()}, ${Sugar.Date.relative(
                    new Date(episode.aired)
                  )}`}
                >
                  <IconCircleCheck className="" color="green" />
                </span>
              ) : (
                <IconClockHour8 className="ml-3 mr-1" color="red" />
              )}
            </div>
          ))}
        <div ref={contentRef}></div>
      </div>
      {totalEpisodes > 10 && (
        <div className="text-center mt-1 mb-6">
          <button
            onClick={toggleShowMore}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      )}
    </div>
  );
};

export default LatestEpisodes;