import { useLoaderData } from 'react-router-dom';
import React from 'react';
import ThemeChangeButton from '../components/themeChangeButton';
import MenuButton from '../components/menuButton';
import TruncatedText from '../components/truncatedText';
import ReactPlayer from 'react-player'
import LatestEpisodes from '../components/truncatedEpisodes';
import Sugar from "sugar";
import { DiscussionEmbed } from 'disqus-react';
import { Helmet } from "react-helmet";


export default function AnimePage() {
    const { anime } = useLoaderData();
    const animeData = anime.detail.data;
    return (
        <div className="animePage">
            <Helmet>
                <title>{animeData.title} | Rakun</title>
                <meta name="description" content={animeData.metadata.synopsis} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${animeData.title} | Rakun`} />
                <meta property="og:description" content={animeData.metadata.synopsis} />
                <meta property="og:image" content={animeData.metadata.poster} />
                <meta property="og:url" content={`https://rakun.app/anime/${animeData.id}`} />
                <meta property="og:site_name" content="Rakun" />
            </Helmet>
            <div className="header flex justify-between">
                <ThemeChangeButton />
                <MenuButton />
            </div>
            <div className="animePageContent">
                <div className="grid grid-cols-4 gap-4">
                    <div className="sm:col-span-2 col-span-4">
                        {animeData.metadata.poster ? (
                            <img className="w-80 mx-auto md:mx-3 m-3 rounded-xl" src={animeData.metadata.poster} alt={animeData.title} />
                        ): (
                            <img className="w-80 mx-auto md:mx-3 m-3 rounded-xl" src="https://via.placeholder.com/300x400?text=No+Poster" alt={animeData.title} />
                        )}
                    </div>
                    <div className="sm:col-span-2 col-span-4">
                        <h1 className="text-3xl font-bold sm:w-full w-80 mx-auto sm:mx-3">{animeData.title}</h1>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.type}, {animeData.content_type}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.metadata.tags.join(", ")}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.metadata.status}</p>
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Watch here:</p>
                        {Array.isArray(animeData.link) ? (
                            animeData.link.map((link, index) => {
                                //get key and value from link object
                                const [key, value] = Object.entries(link)[0];
                                return (
                                    <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                        <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${value}`} alt={key} />
                                        <a href={value} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{key}</a>
                                    </div>
                                )
                            })
                        ) : (
                            <>
                                {Object.entries(animeData.link).map(([key, value], index) => (
                                    <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                        <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${value}`} alt={key} />
                                        <a href={value} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{key}</a>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="sm:col-span-2 col-span-4 sm:mb-8">
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Overview</p>
                        <TruncatedText text={animeData.metadata.synopsis} />
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Airing Status</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.metadata.status ? animeData.metadata.status : "Unknown"}</p>
                        {animeData.metadata.aired ? (
                            <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">From {new Date(animeData.metadata.aired.from).toDateString()} to  
                            <span className="tooltip top bw pl-1" data-tooltip={Sugar.Date.relative(new Date(animeData.metadata.aired.to))}>
                                <span>{new Date(animeData.metadata.aired.to).toDateString()}</span>
                            </span>
                            </p>
                        ) : null}
                        {animeData.titles > 0 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Alternate Titles</p>
                                <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.titles.join(", ")}</p>
                            </>                            
                        ) : null}
                        <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Ratings</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">Ranked #{animeData.metadata.rank}</p>
                        <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">Average Score of {animeData.metadata.score}/10</p>
                        {animeData.metadata.studios > 0? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Studios</p>
                                <p className="text-lg sm:w-full w-80 mx-auto sm:mx-3">{animeData.metadata.studios.join(", ")}</p>
                            </>
                        ) : null}
                        {animeData.metadata.characters.length > 0 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Characters</p>
                                <TruncatedText text={animeData.metadata.characters.join("; ")} />
                            </>
                        ) : null}
                        {animeData.metadata.voice_actors.length > 0 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">Voice Actors</p>
                                <TruncatedText text={animeData.metadata.voice_actors.join("; ")} />
                            </>
                        ) : null}
                        {animeData.metadata.external_links.length > 0 || animeData.mal_id || animeData.ani_id ? (
                            <p className="text-xl font-bold sm:w-full w-80 mx-auto sm:mx-3 mt-8">External Links</p>
                        ) : null}
                        {animeData.mal_id ? (
                            <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1">
                                <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=https://myanimelist.net/`} alt="MyAnimeList" />
                                <a href={`https://myanimelist.net/anime/${animeData.mal_id}`} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">MyAnimeList</a>
                            </div>
                        ) : null}
                        {animeData.ani_id ? (
                            <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1">
                                <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=https://anilist.co/`} alt="AniList" />
                                <a href={`https://anilist.co/anime/${animeData.ani_id}`} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">AniList</a>
                            </div>
                        ) : null}
                        {animeData.metadata.external_links.map((link, index) => {
                            //get key and value from link object
                            return (
                                <div className="flex flex-row sm:w-full w-80 mx-auto sm:mx-3 items-center mt-1" key={index}>
                                    <img className="w-4 h-4 mr-2" src={`https://www.google.com/s2/favicons?domain=${link.url}`} alt={link.name} />
                                    <a href={link.url} target="_blank" rel="noreferrer" className="text-2xl font-bold underline from-[#6366F1] via-[#D946EF] to-[#FB7185] bg-gradient-to-r bg-clip-text text-transparent mr-3">{link.name}</a>
                                </div>
                            )
                        })}
                    </div>
                    <div className="sm:col-span-2 col-span-4 sm:mb-8">
                        {animeData.metadata.trailer ? (
                            <>
                            <p className="text-xl font-bold sm:w-full w-80 sm:mx-3 mt-8">Trailer</p>
                            <div className="m-3">
                                <ReactPlayer url={animeData.metadata.trailer} width="100%" controls onError={(e) => {
                                    console.log(e)
                                }} />
                            </div>
                            </>
                        ) : null}
                        {animeData.metadata.episodes.length > 0 ? (
                            <>
                                <p className="text-xl font-bold sm:w-full w-80 sm:mx-3 mt-8">Episodes</p>
                                <LatestEpisodes animeData={animeData} />
                            </>
                        ) : null}
                    </div>
                    <div className="col-span-4 mx-3">
                        <DiscussionEmbed
                            shortname='rakunapp'
                            config={
                                {
                                    url: `https://rakun.app/anime/${animeData.id}`,
                                    identifier: animeData.id,
                                    title: animeData.title,
                                    language: 'en',
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}