import { IconSunHigh, IconMoonStars } from "@tabler/icons-react"
import React from "react";


export default function ThemeChangeButton() {
    const [theme, setTheme] = React.useState(document.documentElement.getAttribute("data-theme"));
    const handleThemeChange = () => {
        if (theme === "light") {
            setTheme("dark");
            localStorage.setItem("theme", "dark");
            document.documentElement.setAttribute("data-theme", "dark");
        } else {
            setTheme("light");
            localStorage.setItem("theme", "light");
            document.documentElement.setAttribute("data-theme", "light");
        }
    }
    return (
        <div className="toggleChangeButton">
            <button className="flex gap-2 items-center btn bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white m-3" id="theme-toggle" onClick={() => handleThemeChange()}>
            {theme === "light" ? <IconSunHigh /> : <IconMoonStars />}
            </button>
        </div>
      );
}