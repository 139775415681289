import React, { useState, useRef } from 'react';

const TruncatedText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const truncateText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + '...';
  };

  return (
    <>
      <div className="overflow-y-hidden" ref={containerRef}>
        <p className={`text-lg sm:w-full w-80 mx-auto sm:mx-3 transition duration-300 paragraph ${isExpanded ? '' : 'line-clamp-5'}`}>
          {isExpanded ? text : truncateText(text, 140)}
        </p>
      </div>
      {text.length > 140 && (
        <div className="text-center mt-1 mb-6">
          <button
            onClick={toggleExpand}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        </div>
      )}
    </>
  );
};

export default TruncatedText;
