import ThemeChangeButton from '../components/themeChangeButton';
import MenuButton from '../components/menuButton';

export default function AboutPage() {
    return (
        <div className="aboutPage">
            <div className="header flex justify-between">
                <ThemeChangeButton />
                <MenuButton />
            </div>
            <div className="aboutContent text-center pt-30 px-6">
                <h1 className="text-6xl">About Rakun</h1>
            </div>
            <div className="aboutContent py-6 px-10 md:px-40">
                <p className="py-3" antialiased>Every website I have made recently has had one of these about sections, so I think it's natural that I make one for Rakun, too. People would just say to have ChatGPT write it, but I put in too much work on these websites to just have a robot have any say in these things. This also comes to Rakun's poster girl which I had commissioned, to give the website a more personal touch, which I always like and I hope people appreciate.</p>
                <p className="py-3" antialiased>Speaking of the poster girl, her name is Ryuko and she is a cute Raccoon girl who enjoys watching anime, reading manga and reading/watching questionable content. I wanted to have a unique perona for Rakun, and I think she fits it perfectly. She has a cute and fun personality and also has cool heterochromia eyes. If you see her, give her a click and she'll give you one of her random suggestions!</p>
                <p className="py-3" antialiased>When it comes to Anime and Manga, things that people can be very passionate about, I want to make it easier for people to find what they want and get into it quicker. That was the inspiration here and for <a href="https://rezi.one" target="_blank" rel="noreferrer" className="underline underline-offset-2">Rezi</a> as well. I hope you enjoy your time on Rakun and find what you're looking for (which shouldn't be hard).</p> 
            </div>
            <div className="aboutContent text-center py-6">
                <h1 className="text-5xl">Information</h1>
            </div>
            <div className="aboutContent py-6 px-10 md:px-40">
                <p className="py-3 antialiased" >The Rakun database is updated every 24 hours (00:00 GMT+2) with the newest and most updated content. This means that if you see something that is missing, it will most likely be added the next day. Join the <a href="https://discord.gg/47SnjxgBFb" target="_blank" className="underline underline-offset-2" rel="noreferrer">Discord</a> for updates on Rakun.</p>
                <p className="py-3 antialiased"> You should also know, dear reader, that Rakun is extremely powerful in the fact that you can search not only by the Anime name, but also by Studio, Tags/Genre, Voice Actors, Characters and alternate titles. This makes it very easy to find new items based on things you already like.</p>
                <p className="py-3 antialiased" >Rakun is also not perfect, and there may be some things are not working as intended. If that happens to be the case, make an issue on the <a href="https://github.com/Wamy-Dev/Rakun/issues/new" target="_blank" rel="noreferrer" className="underline underline-offset-2">GitHub</a> and I will try to fix it as soon as possible. You may also notice that the links for streams may not match the item you clicked on, and if that is the case, report it in the issues as well for not matching.</p>
                <p className="py-3 antialiased" >The Rakun API is free to use by all, as well as the search API. If you are a developer and want to use it, please read the docs <a href="https://docs.rakun.app" target="_blank" rel="noreferrer" className="underline underline-offset-2">here</a>. The base API (api.rakun.app) uses FastAPI and the search API (search.rakun.app) is based on Meilisearch. Both are free for everyone with (basically) no limits. This could change in the future if it causes problems though. If you would like to use it in a project, I encourage you to do so. Please make sure you credit the Rakun API in your project. Thank you. </p>
                <p className="py-3 antialiased" >Finally I would like to give a short warning about the sources. Some may contain ads, and while Rakun itself will never have ads, the sources may and I have no control over this. Every source that is used is accepted and trusted by the community, so you should not have to worry about anything. I would recommed going in with an adblocker though, just in case.</p>
            </div>
            <div className="aboutContent text-center py-6">
                <h1 className="text-3xl font-light">Thanks for using Rakun</h1>
            </div>
        </div>
    )
}