import React from 'react';
import ThemeChangeButton from '../components/themeChangeButton';
import MenuButton from '../components/menuButton';
import SearchComponent from '../components/searchComponent';


export default function Main() {
  React.useEffect(() => {
    document.title = `Rakun`;
  }, []);
  return (
    <div className="App">
        <div className="header flex justify-between">
          <ThemeChangeButton />
          <MenuButton />
        </div>
        <div className="search">
          <SearchComponent />
        </div>
    </div>
  )
}