import { IconMenu2, IconBrandGithub, IconCurrencyDollar, IconWorldCode, IconAsterisk, IconSearch } from "@tabler/icons-react"
import { Link, useLocation } from "react-router-dom";

export default function MenuButton() {
    let location = useLocation();
    return (
        <div className="menuButton">
            <div className="dropdown bw">
                <label className="flex gap-2 items-center btn bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white m-3" tabIndex="0"><IconMenu2/></label>
                <div className="menu bottom-left m-3">
                    {location.pathname === "/" ? (
                        <Link className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" to="/about">
                            <div className="flex gap-2 items-center">
                                <IconAsterisk />
                                <span>About</span>
                            </div>
                        </Link>
                    ) : (
                        <Link className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" to="/">
                            <div className="flex gap-2 items-center">
                                <IconSearch />
                                <span>Search</span>
                            </div>
                        </Link>
                    )}
                    
                    <a className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" tabIndex="-1" href="https://github.com/Wamy-Dev/Rakun" target="_blank" rel="noreferrer">
                        <div className="flex gap-2 items-center">
                            <IconBrandGithub />
                            <span>GitHub</span>
                        </div>
                    </a>
                    <a className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" tabIndex="-1" href="https://homeonacloud.com/donate" target="_blank" rel="noreferrer">
                        <div className="flex gap-2 items-center">
                            <IconCurrencyDollar />
                            <span>Donate</span>
                        </div>
                    </a>
                    <a className="item text-sm bg-gradient-to-r from-rose-500 to-fuchsia-500 text-white" tabIndex="-1" href="https://docs.rakun.app" target="_blank" rel="noreferrer">
                        <div className="flex gap-2 items-center">
                            <IconWorldCode />
                            <span>API</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}